.rbc-header {
  padding: 10px 0;
}

.rbc-current-time-indicator {
  background-color: red;
}

.rbc-today {
  background-color: #f0faf6;
}

.rbc-event {
  background-color: #f5f3ef;
  border-radius: 0;
  border-left: 4px solid #9e8e5f;
  color: rgb(71, 71, 71);
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #d8cdb8;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border-color: #9e8e5f;
}

.rbc-done-event {
  background-color: #148f64;
  border-radius: 0;
  border-left: 4px solid #148f64;
  color: white;
}

.done-text {
  color: rgb(30, 138, 100);
}

.rbc-show-more {
  color: #148f64;
}

.rbc-toolbar button {
  border: 1px solid #cacaca;
  color: gray;
  cursor: pointer;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  color: rgb(255, 255, 255);
  background-color: rgb(30, 138, 100);
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  box-shadow: none;
  border-color: #148f64;
  background-color: #148f64;
  color: white;
}
