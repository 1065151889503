.main-auth-content {
  position: relative;
  background-image: url('../images/bg_auth.png');
  background-size: cover;
  background-position: 50% 50%;
  height: 100vh;
  background-repeat: no-repeat;
}
.auth-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 600px;
  min-height: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(126, 126, 126);
}
.auth-title {
  text-align: center;
}
.auth-title h2 {
  text-transform: uppercase;
}
.auth-content {
  padding: 10px 10px;
}
.auth-content .auth-content-text p {
  font-size: 16px;
}
.auth-content-types ul li {
  border-radius: 5px;
  border: 1px solid #148f64;
  cursor: pointer;
  /* transition: background-color 0.3s; */
}
/* .auth-content-types ul li:hover {
  background-color: #eee;
} */
.auth-content-types ul li.main-bgColor .login-type {
  color: white;
}
.auth-login-types ul li .login-type p {
  font-weight: bold;
  font-size: 15px;
}
.auth-actions .auth-lang {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-actions {
  margin-bottom: 20px;
}
.auth-actions p {
  text-align: center;
  font-size: 15px;
}
.auth-actions p a {
  color: #148f64;
}
.auth-actions .auth-lang img {
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
}
.auth-content-form {
  margin-top: 20px;
}
.auth-form-label {
  color: #148f64 !important;
}
.auth-password-input input[type='password'] {
  position: relative !important;
}
.auth-password-input i {
  position: absolute;
  top: 80%;
  /* right: 10px; */
  cursor: pointer;
}
.auth-action {
  text-align: center;
  margin: 10px 0;
}
.react-code-input input {
  direction: ltr !important;
}
.react-code-input input:focus {
  direction: ltr !important;
}
.react-code-input input::placeholder {
  direction: ltr !important;
}
