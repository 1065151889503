@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;700&family=Nunito+Sans:wght@300;400;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#root {
  height: 100% !important;
}
.App {
  height: 100% !important;
}

ul {
  list-style: none;
}
.main-bgColor {
  background-color: #148f64 !important;
}
.w-100 {
  width: 100%;
}
html[dir='ltr'] .main-font * {
  font-family: 'Nunito Sans', sans-serif;
}
html[dir='rtl'] .main-font * {
  font-family: 'Almarai', sans-serif;
}
html[dir='ltr'] .main-font i {
  font-family: 'Icons' !important;
}
html[dir='rtl'] .main-font i {
  font-family: 'Icons' !important;
}
input[type='checkbox'] + label {
  color: #148f64 !important;
  font-weight: bold;
}
input[type='checkbox']:checked ~ label::after {
  color: #148f64 !important;
}
::-webkit-scrollbar {
  width: 4px !important;
  transition: width 0.3s !important;
}
::-webkit-scrollbar-track {
  background: none !important;
}
::-webkit-scrollbar-thumb {
  background: #148f64 !important;
}
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background: #148f64 !important;
}
span.e-icons {
  font-family: 'e-icons' !important;
}
.e-time {
  display: none !important;
}

@layer base {
  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
}

.ui.breadcrumb a {
  @apply text-primary !important;
}

.btn-primary {
  @apply bg-primary text-white hover:bg-green-500 transition-colors duration-300 focus:ring-offset-green-400 shadow-md !important;
}

.btn-goldPrimary {
  @apply bg-goldPrimary text-white hover:bg-subTitleText transition-colors duration-300 focus:ring-offset-subTitleText shadow-md !important;
}

.btn-secondary {
  @apply bg-secondary text-white hover:bg-secondary-hover transition-colors duration-300 focus:ring-offset-secondary-hover !important;
}

.btn-default {
  @apply bg-gray-300 text-gray-700 hover:bg-gray-200 transition-colors duration-300 focus:ring-offset-gray-200 !important;
}

.btn-danger {
  @apply bg-red-500 text-white hover:bg-red-400 focus:ring-red-300 transition-colors duration-300 !important;
}

.ui.secondary.pointing.menu .active.item {
  @apply border-primary text-primary !important;
}

.popup::before {
  background: #148f64 !important;
}
.pending {
  @apply text-goldPrimary;
}
.approved,
.started {
  @apply text-primary;
}
.rejected,
.ended {
  @apply text-red-700;
}

.ui.input.focus > input,
.ui.input > input:focus,
.ui.form textarea:focus,
.ui.selection.dropdown:focus {
  @apply border-primary !important;
}

.ui.form .field > label {
  @apply text-primary !important;
}

.ui.tab {
  @apply m-0 border-0 p-0 !important;
}

/* .ui.bottom.attached.segment.active.tab {
  width: fit-content;
} */

.schedule-group.e-schedule .template-wrap {
  display: flex;
  text-align: left;
}

/* custom code start*/
.schedule-demo-heading {
  font-size: 16px;
  padding-bottom: 15px;
  text-align: center;
  font-weight: 500;
}
/* custom code end*/
.schedule-group.e-schedule .e-agenda-view .template-wrap {
  display: table;
  width: inherit;
  text-align: center;
}

.schedule-group.e-schedule:not(.e-device)
  .e-agenda-view
  .e-content-wrap
  table
  td:first-child {
  width: 110px;
}

.schedule-group.e-schedule .template-wrap .airline-image {
  width: 30px;
  margin-top: 15px;
  background-repeat: no-repeat;
}

.schedule-group.e-schedule.e-device .template-wrap .airline-image {
  margin-top: 5px;
}

.schedule-group.e-schedule .e-agenda-view .template-wrap .airline-image {
  height: 30px;
  margin-top: 0;
  margin-left: 35%;
}

.schedule-group.e-schedule .e-vertical-view .e-resource-cells {
  height: 76px;
}

.e-bigger .schedule-group.e-schedule .e-vertical-view .e-resource-cells {
  height: 80px;
}

.schedule-group.e-schedule .template-wrap .airline-details {
  padding-left: 15px;
}

.schedule-group.e-schedule .e-agenda-view .template-wrap .airline-details {
  padding: 0;
}

.schedule-group.e-schedule .template-wrap .airline-details .airline-name {
  font-size: 16px;
  font-weight: 500;
}

.schedule-group.e-schedule.e-device
  .template-wrap
  .airline-details
  .airline-name {
  font-size: inherit;
  font-weight: inherit;
}

.schedule-group.e-schedule .template-wrap .airline-image.airways-1 {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/airways-1.svg');
}

.schedule-group.e-schedule .template-wrap .airline-image.airways-2 {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/airways-2.svg');
}

.schedule-group.e-schedule .template-wrap .airline-image.airways-3 {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/airways-3.svg');
}

.schedule-group.e-schedule .e-agenda-view .template-wrap .airline-model,
.schedule-group.e-schedule .e-agenda-view .template-wrap .airline-seats {
  display: none;
}

.schedule-group.e-schedule.e-device .template-wrap .airline-model,
.schedule-group.e-schedule.e-device .template-wrap .airline-seats {
  display: none;
}
/* custom code start*/
@media (max-width: 550px) {
  .schedule-demo-heading {
    font-size: 14px;
  }
}
/* custom code end*/
